import React from 'react';
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/outline';

const SidebarGroup = ({
  label,
  GroupIcon,
  collapsed,
  isOpen,
  toggleGroup,
  children,
  active,
  unActiveColor = 'text-secondary',
  isBold,
}) => {
  return (
    <div
      className={`w-full rounded-md ml-4 pr-4 ${
        active ? 'bg-primary bg-opacity-10 text-primary' : unActiveColor
      } ${
        isBold ? 'font-semibold' : ''
      } transition-colors duration-200 ease-in-out`}
    >
      {/* Header Section */}
      <div
        onClick={toggleGroup}
        className={`flex flex-row items-center text-sm gap-4 my-2 cursor-pointer p-2 rounded-md transition-all duration-200 ${
          isOpen
            ? 'bg-primary bg-opacity-10 text-primary'
            : 'hover:bg-blue-100 hover:text-primary'
        } ${collapsed ? '2xl:ml-4 md:ml-2 justify-center' : ''}`}
      >
        {/* Icon and Label */}
        <div className='flex items-center gap-4 flex-1 w-4/5 break-words'>
          <GroupIcon className='h-6 flex-shrink-0 text-primary' />
          {!collapsed && <span>{label}</span>}
        </div>

        {/* Chevron Icon */}
        {!collapsed &&
          (isOpen ? (
            <ChevronLeftIcon className='h-5 w-5 min-w-fit transition-transform duration-300' />
          ) : (
            <ChevronDownIcon className='h-5 w-5 min-w-fit transition-transform duration-300' />
          ))}
      </div>

      {/* Children Section */}
      <div
        className={`flex flex-col gap-2 mt-2 transition-all duration-200 ease-in-out ${
          collapsed ? 'hidden' : isOpen ? 'block' : 'hidden'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default SidebarGroup;
