import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';

const Select = ({
  options,
  className,
  placeholder,
  onChange,
  value,
  isDisabled,
  customStyles,
  isMulti,
  loading,
  isSearchable = true,
  closeMenuOnSelect,
  defaultValue,
  isClearable,
  bgGray,
  Icon,
}) => {
  const customStylesDefault = {
    singleValue: (provided, state) => {
      const opacity = typeof value === 'number' || value ? 1 : 0.5;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition, color: '#4b5563' };
    },
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#E9ECF5',
    }),
  };

  const [t] = useTranslation();
  const animatedComponents = makeAnimated();

  const getValue = () => {
    if (isMulti) {
      return options?.filter((o) => value?.includes(o?.value));
    }
    return options?.find((o) => o?.value === value);
  };

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          <div className='flex flex-row gap-2 items-center -ml-2'>
            {!!Icon && <Icon className='h-4 text-primary' />}
            {children}
          </div>
        </components.ValueContainer>
      )
    );
  };
  const SingleValue = ({ children, ...props }) => {
    const Value = props?.selectProps?.value;
    return (
      <components.SingleValue {...props}>
        <div className='flex flex-row gap-2 items-center'>
          {!!Value?.Icon && <Value.Icon className='h-4 text-primary' />}
          {children}
        </div>
      </components.SingleValue>
    );
  };

  function IconOption(props) {
    const {
      data: { label, Icon },
      isSelected,
    } = props;
    return (
      <components.Option {...props}>
        <div className='flex items-center gap-2'>
          {Icon && (
            <Icon
              className={`h-4 ${isSelected ? 'text-white' : 'text-primary'}`}
            />
          )}
          <span>{label}</span>
        </div>
      </components.Option>
    );
  }

  return (
    <ReactSelect
      className={`relative ${!!isDisabled && 'opacity-50'} ` + className}
      styles={customStyles ? customStyles : customStylesDefault}
      options={options}
      placeholder={placeholder ?? t('component.select')}
      onChange={onChange}
      value={getValue()}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isMulti={isMulti}
      isSearchable={isSearchable}
      isLoading={loading}
      closeMenuOnSelect={closeMenuOnSelect}
      defaultValue={getValue(isMulti, options, defaultValue)}
      components={
        isMulti
          ? animatedComponents
          : Icon
          ? { ValueContainer, SingleValue, Option: IconOption }
          : { SingleValue, Option: IconOption }
      }
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#2C59F8',
          primary75: '#fb923c',
          primary50: '#cbd5e1',
          primary25: '#e2e8f0',
          neutral0: bgGray ? '#E9ECF5' : '#f7f7fa',
          neutral5: '',
          neutral10: '',
          neutral20: '',
          neutral30: '',
          neutral40: '',
          neutral50: '',
          neutral60: '',
          neutral70: '',
          neutral80: '',
          neutral90: '',
        },
      })}
    />
  );
};

export default Select;
