import React, { useContext, useState } from 'react';
import SidebarItem from './SidebarItem';
import {
  BookOpenIcon,
  CalculatorIcon,
  LibraryIcon,
  LogoutIcon,
  MenuIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  SwitchHorizontalIcon,
  UserCircleIcon,
  UserGroupIcon,
  ViewGridIcon,
  StarIcon,
  InboxIcon,
  LightBulbIcon,
  CogIcon,
  AcademicCapIcon,
  InformationCircleIcon,
  CalendarIcon,
  ChartBarIcon,
  PuzzleIcon,
  LinkIcon,
  CheckCircleIcon,
  DocumentReportIcon,
  BeakerIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Can, { AbilityContext } from '../../utils/Can';
import { ChildrenContext } from '../../utils/contexts/ChildrenContext';
import { useHistory } from 'react-router-dom';
import TokenService from '../../services/token.service';
import SupportModal from './SupportModal';
import SidebarGroup from './SidebarGroup';

const DOMAIN = 'https://8q.org.kz/login-with-bilorg';

const Sidebar = ({ user, setUser, collapsed, setCollapsed }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const ability = useContext(AbilityContext);
  const { currentChild } = useContext(ChildrenContext);
  const [openGroup, setOpenGroup] = useState(null);
  const handleToggleGroup = (group) => {
    setOpenGroup((prev) => (prev === group ? null : group));
  };

  return user ? (
    <aside
      className={`flex flex-col h-screen transition-all duration-200 ease-in-out ${
        collapsed ? 'w-sidebar-collapsed' : 'w-sidebar'
      }`}
    >
      <div className='flex flex-col h-screen pt-4 justify-start'>
        <div className='flex flex-col gap-1 h-3/4 2xl:h-4/5 md:-mr-3'>
          <div className='ml-4 w-full mb-4 flex flex-row justify-around items-center'>
            {!collapsed && (
              <p className='font-bold text-2xl'>
                BIL <span className='text-primary'>Platform</span>
              </p>
            )}
            <MenuIcon
              className='h-6 cursor-pointer'
              onClick={() => setCollapsed((prev) => !prev)}
            />
          </div>
          <div className='flex flex-col gap-1 h-window-3/4 overflow-y-auto overflow-x-hidden'>
            <SidebarItem
              label={t('navigation.dashboard')}
              Icon={ViewGridIcon}
              active={location.pathname === `/dashboard/${user?.uuid}`}
              to={{
                pathname: `/dashboard/${user?.uuid}`,
                state: { teacher: user },
              }}
              collapsed={collapsed}
            />
            <SidebarItem
              label={t('navigation.profile')}
              Icon={UserCircleIcon}
              active={location.pathname === `/profile/${user?.uuid}`}
              to={{
                pathname: `/profile/${user?.uuid}`,
                state: { teacher: user },
              }}
              collapsed={collapsed}
            />
            <SidebarGroup
              label={t('navigation.school_management')}
              GroupIcon={UserGroupIcon}
              collapsed={collapsed}
              isOpen={openGroup === 'school_management'}
              toggleGroup={() => handleToggleGroup('school_management')}
              active={location.pathname.includes('school_management')}
            >
              {!currentChild && (
                <Can do='manage' on='Accounts'>
                  <SidebarItem
                    label={t('navigation.accounts')}
                    Icon={UserGroupIcon}
                    active={location.pathname.includes('/accounts')}
                    to='/accounts'
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='Teachers'>
                  <SidebarItem
                    label={t('navigation.teachers')}
                    Icon={UserGroupIcon}
                    active={location.pathname.split('/')[1] === 'teachers'}
                    to='/teachers'
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild &&
                !!user?.positions?.some((p) => p?.code === 'PRNC') && (
                  <SidebarItem
                    label={`${t('accounting.bif')} ${t(
                      'profile_page.checklist.transfers',
                    )}`}
                    Icon={UserGroupIcon}
                    active={location.pathname.split('/')[1] === 'bif-transfers'}
                    to='/bif-transfers'
                    collapsed={collapsed}
                  />
                )}
              {!currentChild && (
                <Can do='create' on='Transfers'>
                  <SidebarItem
                    label={t('navigation.transfers')}
                    Icon={SwitchHorizontalIcon}
                    active={location.pathname.includes('/transfers')}
                    to='/transfers'
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='Students'>
                  <SidebarItem
                    label={t('navigation.grades_students')}
                    Icon={UserGroupIcon}
                    active={location.pathname.includes('/students')}
                    to='/students'
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='Schools'>
                  <SidebarItem
                    label={
                      user?.positions?.some(
                        (p) =>
                          p?.code === 'PRNC' ||
                          p?.code === 'VPD' ||
                          p?.code === 'VPC' ||
                          p?.code === 'VPSA',
                      )
                        ? t('navigation.my_school')
                        : t('navigation.schools_stats')
                    }
                    Icon={LibraryIcon}
                    active={location.pathname.split('/')[1] === 'schools'}
                    to='/schools'
                    collapsed={collapsed}
                    isNew
                  />
                </Can>
              )}
            </SidebarGroup>

            <SidebarGroup
              label={t('navigation.reports_stats')}
              GroupIcon={PresentationChartBarIcon}
              collapsed={collapsed}
              active={location.pathname.includes('reports_stats')}
              isOpen={openGroup === 'reports_stats'}
              toggleGroup={() => handleToggleGroup('reports_stats')}
            >
              {!currentChild && (
                <Can do='read' on='AcademicGoals'>
                  <SidebarItem
                    label={t('academic_goals.academic_goals')}
                    Icon={AcademicCapIcon}
                    to='/academic_goals'
                    active={
                      location.pathname.split('/')[1] === 'academic_goals'
                    }
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='TciOverview'>
                  <SidebarItem
                    label={t('navigation.tci_page')}
                    Icon={PresentationChartLineIcon}
                    active={location.pathname === `/tci/${user?.uuid}`}
                    to={{
                      pathname: `/tci/${user?.uuid}`,
                      state: { teacher: user?.uuid },
                    }}
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='TciStats'>
                  <SidebarItem
                    label={t('navigation.tci_stats')}
                    Icon={PresentationChartBarIcon}
                    active={location.pathname === `/tci/stats/${user?.uuid}`}
                    to={{
                      pathname: `/tci/stats/${user?.uuid}`,
                      state: { teacher: user?.uuid },
                    }}
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {currentChild
                ? undefined
                : (user?.head_of_classes?.length ||
                    ability.can('reads', 'TarbieSchoolReport') ||
                    ability.can('read', 'TarbieCoordinatorReport')) && (
                    <SidebarItem
                      label={t('tarbie.tarbie')}
                      Icon={LightBulbIcon}
                      active={location.pathname.split('/')[1] === `tarbie`}
                      to='/tarbie/report'
                      collapsed={collapsed}
                    />
                  )}
              {!currentChild && (
                <Can do='read' on='LabWorks'>
                  <SidebarItem
                    label={t('lab_works.lab_works')}
                    Icon={BeakerIcon}
                    to='/lab_works'
                    active={location.pathname.includes(`/lab_works`)}
                    collapsed={collapsed}
                    isNew
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='TeacherCategory'>
                  <SidebarItem
                    label={t('navigation.ped_category')}
                    Icon={ChartBarIcon}
                    active={location.pathname === `/pedagogical-level`}
                    to='/pedagogical-level'
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='Psychology'>
                  <SidebarItem
                    label={t('psychology.psychology')}
                    Icon={PuzzleIcon}
                    to='/psychology'
                    active={location.pathname.includes(`/psychology`)}
                    collapsed={collapsed}
                  />
                </Can>
              )}
            </SidebarGroup>

            <SidebarGroup
              label={t('navigation.assessments')}
              GroupIcon={BookOpenIcon}
              collapsed={collapsed}
              active={location.pathname.includes('assessments')}
              isOpen={openGroup === 'assessments'}
              toggleGroup={() => handleToggleGroup('assessments')}
            >
              <Can do='read' on='ExaminationResult'>
                <SidebarItem
                  label={t('navigation.examinations')}
                  Icon={BookOpenIcon}
                  to='/examinations'
                  active={location.pathname.includes(`/examinations`)}
                  collapsed={collapsed}
                />
              </Can>
              {!currentChild && (
                <Can do='read' on='UNT'>
                  <SidebarItem
                    label={t('unt.unt')}
                    Icon={CheckCircleIcon}
                    to='/unt'
                    active={location.pathname.includes(`/unt`)}
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='ExamReports'>
                  <SidebarItem
                    label={t('navigation.exam_reports')}
                    Icon={DocumentReportIcon}
                    to='/exam_reports'
                    active={location.pathname.includes(`/exam_reports`)}
                    collapsed={collapsed}
                    isNew
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='OlympiadResults'>
                  <SidebarItem
                    label={t('navigation.olympiad_results')}
                    Icon={BookOpenIcon}
                    to='/olympiadResults'
                    active={location.pathname.includes(`/olympiadResults`)}
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {/* {currentChild
              ? undefined
              : (ability.can('reads', 'OlympiadReportForTeachers') ||
                  ability.can('reads', 'OlympiadReportForSchool') ||
                  ability.can('read', 'OlympiadReportForCoordinator')) && (
                  <SidebarItem
                    label={t('navigation.olympiad')}
                    Icon={BookOpenIcon}
                    active={location.pathname.includes(`/olympiad/report`)}
                    to='/olympiad/report'
                    collapsed={collapsed}
                  />
                )} */}
            </SidebarGroup>
            {/* {!currentChild && (
              <SidebarItem
                label={t('navigation.qundylyq')}
                Icon={LightBulbIcon}
                to='/qundylyq'
                active={location.pathname.split('/')[1] === 'qundylyq'}
                collapsed={collapsed}
              />
            )} */}
            <SidebarGroup
              label={t('navigation.administrative')}
              GroupIcon={LibraryIcon}
              collapsed={collapsed}
              active={location.pathname.includes('administrative')}
              isOpen={openGroup === 'administrative'}
              toggleGroup={() => handleToggleGroup('administrative')}
            >
              {!currentChild && (
                <Can do='access' on='BILStatus'>
                  <Can do='read' on='Accounting'>
                    <SidebarItem
                      label={t('navigation.accounting')}
                      Icon={CalculatorIcon}
                      to='/accounting'
                      active={location.pathname.split('/')[1] === 'accounting'}
                      collapsed={collapsed}
                    />
                  </Can>
                </Can>
              )}
              <SidebarItem
                label={t('navigation.announcements')}
                Icon={InboxIcon}
                active={location.pathname === `/announcement/feed`}
                to={{
                  pathname: `/announcement/feed`,
                  state: { teacher: user?.uuid },
                }}
                collapsed={collapsed}
              />
              {!currentChild && (
                <Can do='create' on='GenerateChecklist'>
                  <SidebarItem
                    label={t('profile_page.checklist.checklist')}
                    Icon={SwitchHorizontalIcon}
                    to='/checklist'
                    active={location.pathname.includes(`/checklist`)}
                    collapsed={collapsed}
                  />
                </Can>
              )}
              {!currentChild && (
                <Can do='read' on='Calendar'>
                  <SidebarItem
                    label={t('navigation.events')}
                    Icon={CalendarIcon}
                    to='/calendar'
                    active={location.pathname.split('/')[1] === 'calendar'}
                    collapsed={collapsed}
                  />
                </Can>
              )}
            </SidebarGroup>
            {ability.can('read', 'AdminSkillsPage') && !currentChild ? (
              <SidebarItem
                label={t('navigation.courses')}
                Icon={StarIcon}
                active={
                  location.pathname.split('/')[1] === `admin` &&
                  location.pathname.split('/')[2] === 'skills'
                }
                to={{
                  pathname: `/admin/skills`,
                }}
                collapsed={collapsed}
              />
            ) : (
              <SidebarItem
                label={t('navigation.courses')}
                Icon={StarIcon}
                active={location.pathname.split('/')[1] === `skills`}
                to={{
                  pathname: `/skills`,
                  state: { account: user },
                }}
                collapsed={collapsed}
              />
            )}
            <SidebarItem
              label={t('navigation.settings')}
              Icon={CogIcon}
              active={location.pathname === `/settings`}
              to={{ pathname: `/settings` }}
              collapsed={collapsed}
              isBold={currentChild}
            />
          </div>
        </div>
        <div className='flex flex-col gap-2 pt-2 h-full pb-6 justify-end'>
          {!currentChild && (
            <div
              className={`w-full rounded-md bg-blue-200 ml-4 hover:bg-blue-600 hover:text-white cursor-pointer text-blue-600`}
              onClick={() => {
                window.open(`${DOMAIN}`, 'studentIdpTab');
              }}
            >
              <div
                className={`flex flex-row gap-2 items-center my-2 whitespace-nowrap font-semibold ${
                  collapsed ? 'justify-center' : 'mr-24 ml-4'
                }`}
              >
                <LinkIcon className='h-6' />
                {!collapsed && t('navigation.myidp')}
              </div>
            </div>
          )}
          <div className={`flex flex-col font-bold ${collapsed ? 'ml-4' : ''}`}>
            {user?.positions?.some((pos) => pos?.priority >= 50) && (
              <SupportModal collapsed={collapsed} />
            )}
          </div>

          {!currentChild && (
            <div className='flex flex-col font-bold ml-2 '>
              <SidebarItem
                label={t('navigation.instructions')}
                Icon={InformationCircleIcon}
                active={location.pathname.split('/')[1] === `instructions`}
                to='/instructions'
                unActiveColor='text-red-400'
                collapsed={collapsed}
              />
            </div>
          )}
          <div
            className={`w-full rounded-md bg-gray-200 ml-4 hover:text-warning hover:bg-warning hover:bg-opacity-10 cursor-pointer text-secondary`}
            onClick={() => {
              TokenService.removeTokens();
              setUser(null);
              history.push('/login');
            }}
          >
            <div
              className={`flex flex-row gap-4 my-2 items-center font-semibold ${
                collapsed ? 'justify-center' : 'mr-24 ml-4'
              }`}
            >
              <LogoutIcon className='h-6' />
              {!collapsed && t('navigation.logout')}
            </div>
          </div>
        </div>
      </div>
    </aside>
  ) : null;
};

export default Sidebar;
