import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SidebarItem = ({
  label,
  Icon,
  to,
  active,
  collapsed,
  unActiveColor = 'text-secondary',
  isNew,
  isBold,
}) => {
  const [t] = useTranslation();
  return (
    <div
      className={`w-full hover:bg-blue-100 rounded-md ml-4 ${
        active ? 'bg-primary bg-opacity-10 text-primary' : unActiveColor
      } ${isBold ? 'font-semibold' : ''}`}
    >
      <Link to={to} className='hover:text-primary'>
        <div
          className={`flex flex-row gap-4 my-2 px-2 items-center ${
            collapsed ? 'justify-center' : 'mr-4'
          }`}
        >
          {Icon ? (
            <Icon className='h-6 flex-shrink-0' />
          ) : (
            <div className='h-4 w-4 rounded-full bg-gray-400 flex-shrink-0'></div>
          )}
          <div className='flex flex-row-reverse w-full justify-between items-center overflow-hidden'>
            <div className='min-w-fit'>
              {isNew && !collapsed && (
                <span className='text-error animate-pulse min-w-fit'>
                  {t('students.new')}
                </span>
              )}
            </div>
            {!collapsed && <span className='break-normal'>{label}</span>}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SidebarItem;
